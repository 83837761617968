




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import { Notify } from 'quasar';
import { placeModule } from '@/store';
import Swiper from 'swiper';

declare const Kakao: any;

@Component
export default class PlaceInfo extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public place!: any;
  @Prop()
  public selectLang!: any;
  public swiper: any = null;

  public mounted() {
    this.activeSwiper();
  }
  public updated() {
    this.activeSwiper();
  }
  public activeSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    const swiper = new Swiper('.place-img-list .swiper-container', {
      slidesPerView: 'auto',
      observer: true,
      observeParents: true,
      spaceBetween: -10,
    });
  }

  public copyNoti() {
    this.$q.notify('주소가 복사되었습니다.');
  }
  public navigation() {
    const map1 = this.place.coordinate ? this.place.coordinate[0] : null;
    const map2 = this.place.coordinate ? this.place.coordinate[1] : null;
    Kakao.Navi.start({
      name: this.place.name,
      y: map1,
      x: map2,
      coordType: 'wgs84'
    });
  }
  public mapMove() {
    const placeName = encodeURIComponent(this.place.name);
    const appName = encodeURIComponent('https://m.poing.io/');
    const map1 = this.place.coordinate ? this.place.coordinate[0] : null;
    const map2 = this.place.coordinate ? this.place.coordinate[1] : null;

    const url = `route/walk?dlat=${map1}&dlng=${map2}&dname=${placeName}&appname=${appName}`;
    const iosUrl = `nmap://${url}`;
    const androidUrl = `intent://${url}#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end`;
    const webUrl = `https://m.search.naver.com/search.naver?query=${encodeURIComponent('빠른길찾기')}`
      + `&nso_path=placeType^place;name^;address^;latitude^;longitude^;code^|type^place;`
      + `name^${placeName};latitude^${map1};longitude^${map2};code^|objtype^path;by^`;

    if (this.$q.platform.is.mobile) {
      if (this.$q.platform.is.ios) { //ios
        const agent = navigator.userAgent;
        if (agent.includes('CriOS')) {
          // 크롬
          this.iosMapMove(iosUrl);
        } else {
          // location.href = webUrl;
          window.open(webUrl);
        }
      } else { //android
        // location.href = androidUrl;
        window.open(androidUrl);
      }
    } else { //desktop
      window.open(webUrl);
    }
  }
  public iosMapMove(url: any) {
    const clickedAt = +new Date();
    location.href = url;
    setTimeout(() => {
      if (+new Date() - clickedAt < 2000) {
        location.href = 'http://itunes.apple.com/app/id311867728?mt=8';
      }
    }, 1500);
  }
  get mapUrl() {
    const map1 = this.place.coordinate ? this.place.coordinate[0] : null;
    const map2 = this.place.coordinate ? this.place.coordinate[1] : null;
    return `https://map.kakao.com/link/to/${this.place.name},${map1},${map2}`;
  }
  get taxiUrl() {
    const map1 = this.place.coordinate ? this.place.coordinate[0] : null;
    const map2 = this.place.coordinate ? this.place.coordinate[1] : null;
    return `http://t.kakao.com/launch?type=taxi&dest_lat=${map1}&dest_lng=${map2}`;
  }
}
